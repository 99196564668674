import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { mainWhite, mainPink, darkGray, screen } from "../common/variables";
import { MdKeyboardArrowDown } from "react-icons/md";
import { ScrollLocky } from "react-scroll-locky";
import IconFilter from "../../images/svg/filter-icon.svg";
import IconClose from "../../images/svg/close-icon.svg";
import sortByDistance from "sort-by-distance";

const variantsContainer = {
  open: { opacity: 1, scaleY: 1, pointerEvents: "all" },
  close: { opacity: 0, scaleY: 0.85, pointerEvents: "none" },
};

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  z-index: 2;
  top: 0;

  @media ${screen.xsmall} {
    padding: 0 70px 0 0;
  }
  @media ${screen.large} {
    padding: 0;
  }

  .filter-btn {
    display: block;
    position: absolute;
    right: 35px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    top: 3px;
    @media ${screen.xsmall} {
      display: none;
    }
  }

  .control-filter {
    position: relative;
    min-width: 298px;

    .selected-label {
      display: none;
      @media ${screen.xsmall} {
        display: block;
        color: #313532;
        cursor: pointer;
        font-size: 0.95rem;
        border-radius: 34px;
        background: ${mainWhite};
        box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
        position: relative;
        padding: 16px 70px 16px 28px;
        line-height: 1.5;
      }
      svg {
        cursor: pointer;
        font-size: 2rem;
        position: absolute;
        right: 14px;
        top: calc(50% + 2px);
        transform: translateY(-50%);
      }
    }

    .option-list {
      position: fixed;
      box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      min-width: 298px;
      background: ${mainWhite};
      overflow: hidden;
      z-index: 99;
      @media ${screen.xsmall} {
        border-radius: 24px;
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        bottom: auto;
      }

      &__heading {
        background: #f5f5f5;
        display: block;
        font-size: 1.25rem;
        text-align: center;
        padding: 30px 0;
        position: relative;
        @media ${screen.xsmall} {
          display: none;
        }

        svg {
          position: absolute;
          width: 16px;
          height: 16px;
          left: 35px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }

      &__each {
        color: #313532;
        font-size: 1rem;
        padding: 28px 35px 28px 60px;
        cursor: pointer;
        border-bottom: #c1c1c1 1px solid;
        transition: all 0.13s ease-out;
        position: relative;
        @media ${screen.xsmall} {
          font-size: 0.95rem;
          padding: 16px 28px;
        }

        &:last-child {
          border-bottom: none;
        }

        @media ${screen.withCursor} {
          &:hover {
            background: rgba(234, 84, 130, 0.15);
            color: ${mainPink};
          }
        }

        .radio {
          border-radius: 50%;
          border: 1px ${darkGray} solid;
          width: 16px;
          height: 16px;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 35px;
          @media ${screen.xsmall} {
            display: none;
          }

          &::after {
            content: "";
            border-radius: 50%;
            width: 10px;
            height: 10px;
            display: block;
            left: 50%;
            top: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
          }
        }

        &--selected {
          background: rgba(234, 84, 130, 0.15);
          color: ${mainPink};

          .radio {
            border: 1px ${mainPink} solid;
            &::after {
              background: ${mainPink};
            }
          }
        }
      }
    }
  }
`;

const FilterOptions = ({
  toggleFilter,
  selectedFilter,
  isFilterOpen,
  isGeoLocationEnabled,
  setSelectedFilter,
  rides,
  allRides,
  setAllRides,
  latitude,
  longitude,
}) => {
  const handleFilter = e => {
    setSelectedFilter(e.target.id);
    const distanceLongToShort =
      e.target.id === "Distance - Longest to shortest";
    const distanceShortToLong =
      e.target.id === "Distance - Shortest to longest";
    const durationLongToShort = e.target.id === "Time - Longest to shortest";
    const durationShortToLong = e.target.id === "Time - Shortest to longest";
    const nearest = e.target.id === "Nearest to me";

    if (distanceShortToLong) {
      const filtered = allRides.sort(
        (a, b) => a.staticDistance - b.staticDistance
      );
      setAllRides(filtered);
    }

    if (distanceLongToShort) {
      const filtered = allRides.sort(
        (a, b) => b.staticDistance - a.staticDistance
      );
      setAllRides(filtered);
    }

    if (durationLongToShort) {
      const filtered = allRides.sort(
        (a, b) => b.approximate_time - a.approximate_time
      );
      setAllRides(filtered);
    }

    if (durationShortToLong) {
      const filtered = allRides.sort(
        (a, b) => a.approximate_time - b.approximate_time
      );
      setAllRides(filtered);
    }

    if (nearest) {
      const origin = { latitude: latitude, longitude: longitude };
      const opts = {
        yName: "latitude",
        xName: "longitude",
      };

      setAllRides(sortByDistance(origin, rides, opts));
    }
  };
  return (
    <Wrapper>
      <div className="control-filter">
        <div className="filter-btn" role="button" onClick={toggleFilter}>
          <IconFilter />
        </div>
        <div role="button" onClick={toggleFilter} className="selected-label">
          {selectedFilter} <MdKeyboardArrowDown />
        </div>

        <ScrollLocky enabled={isFilterOpen} isolation={false}>
          <motion.ul
            initial="close"
            animate={isFilterOpen ? "open" : "close"}
            variants={variantsContainer}
            transition={{ duration: 0.17 }}
            className="option-list"
          >
            <span className="option-list__heading">
              <IconClose />
              SORT BY
            </span>
            <li
              onClick={handleFilter}
              className={
                selectedFilter !== "Distance - Longest to shortest"
                  ? "option-list__each"
                  : "option-list__each option-list__each--selected"
              }
              id="Distance - Longest to shortest"
            >
              Distance - Longest to shortest
              <span className="radio"></span>
            </li>
            <li
              onClick={handleFilter}
              className={
                selectedFilter !== "Distance - Shortest to longest"
                  ? "option-list__each"
                  : "option-list__each option-list__each--selected"
              }
              id="Distance - Shortest to longest"
            >
              {" "}
              Distance - Shortest to longest
              <span className="radio"></span>
            </li>
            <li
              onClick={handleFilter}
              className={
                selectedFilter !== "Time - Longest to shortest"
                  ? "option-list__each"
                  : "option-list__each option-list__each--selected"
              }
              id="Time - Longest to shortest"
            >
              Time - Longest to shortest
              <span className="radio"></span>
            </li>
            <li
              onClick={handleFilter}
              className={
                selectedFilter !== "Time - Shortest to longest"
                  ? "option-list__each"
                  : "option-list__each option-list__each--selected"
              }
              id="Time - Shortest to longest"
            >
              Time - Shortest to longest
              <span className="radio"></span>
            </li>
            {isGeoLocationEnabled && (
              <li
                onClick={handleFilter}
                className={
                  selectedFilter !== "Nearest to me"
                    ? "option-list__each"
                    : "option-list__each option-list__each--selected"
                }
                id="Nearest to me"
              >
                Nearest to me
                <span className="radio"></span>
              </li>
            )}
          </motion.ul>
        </ScrollLocky>
      </div>
    </Wrapper>
  );
};

export default FilterOptions;
