import React, { useState, useEffect } from "react";
import { Link, graphql, navigate } from "gatsby";
import SEO from "../components/seo";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  innerWidth,
  darkGray,
  mainBlack,
  mainGreen,
  mainWhite,
  screen,
} from "../components/common/variables";
import { useSwipeable } from "react-swipeable";
import BackgroundImage from "gatsby-background-image";
import Arrow from "../images/svg/arrow.svg";
import { slugify, timeConvert, timeFormat } from "../components/common/helpers";
import Obfuscate from "react-obfuscate";
import FilterOptions from "../components/rides/filter-options";

const Wrapper = styled.div`
  background: #f5f5f5;
  position: relative;
  z-index: ${(props) => (props.isFilterOpen ? 99 : 1)};
  @media ${screen.xsmall} {
    z-index: 1;
  }

  .rides {
    padding-top: 123px;
    padding-bottom: 40px;
    @media ${screen.xsmall} {
      min-height: 100vh;
      padding-top: 150px;
      padding-bottom: 150px;
    }

    &__top-section {
      max-width: ${innerWidth};
      position: relative;
      margin: 0 auto 37px auto;
      padding: 0 30px;
      @media ${screen.xsmall} {
        padding: 0 70px;
        margin: 0 auto 65px auto;
      }
      @media ${screen.large} {
        padding: 0;
      }

      .page-title {
        color: ${darkGray};
        font-weight: 500;
        font-size: 1.25rem;
        @media ${screen.xsmall} {
          font-size: 1.68rem;
        }
        @media ${screen.medium} {
          font-size: 1.7rem;
        }
      }
    }

    .rides-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      @media ${screen.xsmall} {
        padding: 0 70px;
      }
      @media ${screen.large} {
        padding: 0;
      }

      .ride-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        @media ${screen.xsmall} {
          margin: 0 -14px;
        }

        &__each {
          box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          margin: 0 12px 18px 12px;
          border-radius: 14px;
          position: relative;
          width: 100%;
          @media ${screen.xsmall} {
            margin: 0 14px 28px 14px;
            width: calc(100% - 28px);
          }
          @media ${screen.small} {
            width: calc(50% - 28px);
          }
          @media ${screen.medium} {
            width: calc(33.33% - 28px);
          }

          .link-wrapper {
            display: flex;
            align-items: center;
            transition: all 0.13s ease-out;
            background: ${mainWhite};
            padding: 14px;
            border-radius: 14px;

            @media ${screen.withCursor} {
              &:hover {
                background: ${mainGreen};
              }

              &:hover .texts {
                color: ${mainWhite};
              }
              &:hover .icon-arrow .arrow-fill {
                fill: ${mainWhite};
              }
            }

            .thumbnail-img {
              width: 65px;
              height: 65px;
              border-radius: 14px;
              overflow: hidden;
              pointer-events: none;
              @media ${screen.xsmall} {
                width: 95px;
                height: 95px;
              }
              &::before {
              }
            }

            .texts {
              margin-left: 25px;
              color: ${mainBlack};
              transition: all 0.13s ease-out;
              pointer-events: none;
              @media ${screen.xsmall} {
                margin-left: 20px;
              }
              @media ${screen.small} {
                max-width: 200px;
              }
              @media ${screen.medium} {
                max-width: none;
                max-width: 170px;
              }
              @media ${screen.large} {
                max-width: none;
                margin-left: 28px;
              }
              &__title {
                font-size: 1.12rem;
                margin-bottom: 2px;
                max-width: 188px;
                @media ${screen.xsmall} {
                  font-size: 1.2rem;
                  margin-bottom: 5px;
                  max-width: none;
                }
              }

              &__measure {
                font-size: 0.88rem;
                @media ${screen.xsmall} {
                  font-size: 0.95rem;
                }
              }
            }

            .icon-arrow {
              width: 23px;
              height: 23px;
              position: absolute;
              right: 28px;
              top: 50%;
              transform: translateY(-50%);
              pointer-events: none;
              @media ${screen.xsmall} {
                width: 40px;
                height: 40px;
              }

              .arrow-fill {
                transition: all 0.13s ease-out;
                fill: ${mainBlack};
              }
            }
          }
        }
      }
    }
  }

  #sponsoring {
    background: ${mainGreen};
    padding: 26px 0;
    @media ${screen.xsmall} {
      padding: 50px 0;
    }

    .sponsoring-wrapper {
      text-align: center;
      &__heading {
        color: ${darkGray};
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 10px;
        @media ${screen.xsmall} {
          font-size: 1.95rem;
        }
      }

      &__cta {
        color: ${mainBlack};
        @media ${screen.xsmall} {
          text-decoration: underline;
          font-size: 1.3rem;
        }

        &--lg {
          display: none;
          @media ${screen.xsmall} {
            display: block;
          }
        }

        &--sm {
          border-radius: 30px;
          box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
          display: block;
          margin: 0 35px;
          background: ${mainWhite};
          padding: 20px 0;
          font-size: 1rem;
          @media ${screen.xsmall} {
            display: none;
          }
        }
      }
    }
  }
`;

// motion framer animation props value
const variantsContainer = {
  open: { opacity: 1 },
  close: { opacity: 0 },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 100, velocity: -100 },
    },
  },
  close: {
    y: 60,
    opacity: 0,
    transition: {
      y: { stiffness: 100 },
    },
  },
};

const RidesPage = (props) => {
  // user geolocation props passed from layout component
  const { latitude, longitude } = props;

  const { title, title_tag, meta_description } = props.data.content.data;

  // formatted array of each ride object
  const rides = props.data.rides.edges
    .map((item) => {
      return {
        id: item.node.id,
        name: item.node.data.title.text,
        latitude: item.node.data.from.latitude,
        longitude: item.node.data.from.longitude,
        featured_image_landscape: item.node.data.featured_image_landscape.fluid,
        staticDistance: item.node.data.distance,
        approximate_time: item.node.data.approximate_time,
        slug: slugify(item.node.data.title.text),
      };
    })
    .sort((a, b) => a.staticDistance - b.staticDistance);
  // open/close filter
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [enterRideList, setEnterRideList] = useState(false);

  // ride list to be rendered on the page
  const [allRides, setAllRides] = useState(rides);

  // check if user has allowed the browser location
  // dont include "Nearest to me" filter if latitude and longitude dont exist
  const isGeoLocationEnabled = latitude && longitude ? true : false;

  // default selected filter
  const [selectedFilter, setSelectedFilter] = useState(
    "Distance - Shortest to longest"
  );

  // swipe event handler
  const swipeHandler = useSwipeable({
    onSwipedLeft: (data) => {
      // navigate to different page if user has swipe left on link element
      if (data.event.target.pathname) navigate(data.event.target.pathname);
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  function toggleFilter() {
    setIsFilterOpen(!isFilterOpen);
  }

  // effect for event listeners
  useEffect(() => {
    // close filter list if user has clicked anywhere
    const userClick = (e) => {
      if (isFilterOpen) {
        if (e.target.className !== "option-list__each") setIsFilterOpen(false);
      }
    };

    // close filter list if user has pressed esc key
    const userPress = (e) => {
      if (isFilterOpen) {
        if (e.keyCode === 27) setIsFilterOpen(false);
      }
    };

    window.addEventListener("click", userClick);
    window.addEventListener("keyup", userPress);

    return () => {
      window.removeEventListener("click", userClick);
      window.removeEventListener("keyup", userPress);
    };
  }, [isFilterOpen]);

  // start animate the ride list on page load
  useEffect(() => {
    setEnterRideList(true);
  }, []);

  const ogUrl = typeof window !== "undefined" && window.location.href;

  return (
    <>
      <SEO title={title_tag} description={meta_description} url={ogUrl} />
      <Wrapper isFilterOpen={isFilterOpen}>
        <div className="rides">
          <div className="rides__top-section">
            <h2 className="page-title">{title.text}</h2>
            <FilterOptions
              toggleFilter={toggleFilter}
              selectedFilter={selectedFilter}
              isFilterOpen={isFilterOpen}
              isGeoLocationEnabled={isGeoLocationEnabled}
              setSelectedFilter={setSelectedFilter}
              rides={rides}
              allRides={allRides}
              setAllRides={setAllRides}
              latitude={latitude}
              longitude={longitude}
            />
          </div>
          <motion.div
            className="rides-wrapper"
            initial="close"
            variants={variantsContainer}
            animate={enterRideList ? "open" : "close"}
          >
            <motion.div
              {...swipeHandler}
              className="ride-list"
              variants={variantsList}
            >
              {allRides.map((item) => (
                <motion.div
                  className="ride-list__each"
                  key={item.id}
                  variants={variantsItem}
                >
                  <Link
                    to={`/rides/${item.slug}/`}
                    className="link-wrapper"
                    title={`${item.name} - Bike Ride`}
                  >
                    <BackgroundImage
                      className="thumbnail-img"
                      fluid={item.featured_image_landscape}
                    ></BackgroundImage>
                    <div className="texts">
                      <h2 className="texts__title">{item.name}</h2>
                      <span className="texts__measure">
                        Distance: {item.staticDistance}
                        {item.staticDistance >= 2 ? "kms" : "km"} | Time:{" "}
                        {timeConvert(item.approximate_time)}
                        {timeFormat(item.approximate_time)}
                      </span>
                    </div>
                    <Arrow className="icon-arrow" />
                  </Link>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        </div>

        <section id="sponsoring">
          <div className="sponsoring-wrapper">
            <h3 className="sponsoring-wrapper__heading">
              WANT TO SPONSOR A RIDE?
            </h3>
            <Obfuscate
              className="sponsoring-wrapper__cta sponsoring-wrapper__cta--lg"
              email={"hello@peddl.in"}
            >
              Get in contact with us!
            </Obfuscate>
            <Obfuscate
              className="sponsoring-wrapper__cta sponsoring-wrapper__cta--sm"
              email={"hello@peddl.in"}
            >
              GET IN TOUCH
            </Obfuscate>
          </div>
        </section>
      </Wrapper>
    </>
  );
};

export default RidesPage;

export const dataQuery = graphql`
  {
    content: prismicRidesPage {
      data {
        title {
          text
        }
        title_tag
        meta_description
      }
    }
    rides: allPrismicRide(
      sort: { order: DESC, fields: first_publication_date }
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            distance
            approximate_time
            from {
              latitude
              longitude
            }
            featured_image_landscape {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
